import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'
import axios from 'axios'
import { useMountEffect } from '../../hooks/MountEffect'
import { SmallCriticalHeartbeat, SmallGoodHeartbeat, SmallWarningHeartbeat } from '../AnimatedSvg/AnimatedSvg'
import { ExclaimIcon, InfoIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'
import styles from './HealthThresholdSettings.module.css'

interface Props {
  teamID: string
  readOnly: boolean
}

interface ThresholdValues {
  critical: number
  warning: number
}

interface ThresholdSettings {
  name: string
  cpu: ThresholdValues
  databaseConnections: ThresholdValues
  databaseResponseTimeMillis: ThresholdValues
  diskSpaceFreeGb: ThresholdValues
  memoryUsedPercentage: ThresholdValues
  devicesDownPercentage: ThresholdValues
  mobilityPrintOfflinePercentage: ThresholdValues
  printProviderOfflinePercentage: ThresholdValues
  siteServerOfflinePercentage: ThresholdValues
}

const defaultThresholdValues: ThresholdValues = {
  critical: 0,
  warning: 0,
}

type SetThresholdFunc = Dispatch<SetStateAction<ThresholdValues>>

export const HealthThresholdSettings: FunctionComponent<Props> = ({ teamID, readOnly }) => {
  const [loaded, setLoaded] = useState(false)
  const [cpu, setCpu] = useState(defaultThresholdValues)
  const [memory, setMemory] = useState(defaultThresholdValues)
  const [databaseConnections, setDatabaseConnections] = useState(defaultThresholdValues)
  const [databaseResponseTime, setDatabaseResponseTime] = useState(defaultThresholdValues)
  const [diskspace, setDiskspace] = useState(defaultThresholdValues)
  const [devices, setDevices] = useState(defaultThresholdValues)
  const [mobility, setMobility] = useState(defaultThresholdValues)
  const [printProvider, setPrintProvider] = useState(defaultThresholdValues)
  const [siteServer, setSiteServer] = useState(defaultThresholdValues)
  const [name, setName] = useState('')
  const [errors, setErrors] = useState<string[]>([])
  const [defaultThresholds, setDefaultThresholds] = useState<ThresholdSettings>({
    name: 'Default',
    databaseConnections: { critical: 0, warning: 0 },
    databaseResponseTimeMillis: { critical: 0, warning: 0 },
    diskSpaceFreeGb: { critical: 0, warning: 0 },
    memoryUsedPercentage: { critical: 0, warning: 0 },
    cpu: { critical: 0, warning: 0 },
    devicesDownPercentage: { critical: 0, warning: 0 },
    mobilityPrintOfflinePercentage: { critical: 0, warning: 0 },
    printProviderOfflinePercentage: { critical: 0, warning: 0 },
    siteServerOfflinePercentage: { critical: 0, warning: 0 },
  })

  const getSettings = (forTeamID: string) => {
    axios
      .get<ThresholdSettings>('/api/teams/' + forTeamID + '/settings/health/thresholds')
      .then(result => {
        const setting = result.data
        setName(setting.name)
        setCpu(setting.cpu)
        setDatabaseConnections(setting.databaseConnections)
        setDiskspace(setting.diskSpaceFreeGb)
        setDatabaseResponseTime(setting.databaseResponseTimeMillis)
        setMemory(setting.memoryUsedPercentage)
        setDevices(setting.devicesDownPercentage)
        setMobility(setting.mobilityPrintOfflinePercentage)
        setPrintProvider(setting.printProviderOfflinePercentage)
        setSiteServer(setting.siteServerOfflinePercentage)
        setLoaded(true)
      })
      .catch(_ => {
        Toaster.notifyFailure('Unable to load threshold settings.')
      })
  }

  const saveSettings = () => {
    if (!valid()) {
      return
    }

    const newSettings: ThresholdSettings = {
      name: name,
      cpu: cpu,
      databaseConnections: databaseConnections,
      databaseResponseTimeMillis: databaseResponseTime,
      diskSpaceFreeGb: diskspace,
      memoryUsedPercentage: memory,
      devicesDownPercentage: devices,
      mobilityPrintOfflinePercentage: mobility,
      printProviderOfflinePercentage: printProvider,
      siteServerOfflinePercentage: siteServer,
    }
    axios
      .put('/api/teams/' + teamID + '/settings/health/thresholds', newSettings)
      .then(_ => Toaster.notifySuccess('Changes saved.'))
      .catch(_ => Toaster.notifyFailure('Unable to save changes.'))
  }

  const getDefaultThresholds = () => {
    axios.get<ThresholdSettings>('/api/health/default-thresholds').then(result => {
      setDefaultThresholds(result.data)
      resetToDefaults(result.data)
    })
  }

  const valid = () => {
    let errorsFound: string[] = []
    errorsFound = errorsFound.concat(validateGreaterThanThresholdValues('CPU utilization', cpu, 50, 99))
    errorsFound = errorsFound.concat(validateGreaterThanThresholdValues('Memory used', memory, 40, 90))
    errorsFound = errorsFound.concat(validateLessThanThresholdValues('Disk space free', diskspace, 1, 25))
    errorsFound = errorsFound.concat(validateGreaterThanThresholdValues('Database response time', databaseResponseTime, 10, 1000))
    errorsFound = errorsFound.concat(validateGreaterThanThresholdValues('Database connections used', databaseConnections, 40, 1000))
    errorsFound = errorsFound.concat(validateGreaterThanThresholdValues('Print providers offline', printProvider, 0, 100))
    errorsFound = errorsFound.concat(validateGreaterThanThresholdValues('Site servers offline', siteServer, 0, 100))
    errorsFound = errorsFound.concat(validateGreaterThanThresholdValues('BYOD/Mobility print servers offline', mobility, 0, 100))
    errorsFound = errorsFound.concat(validateGreaterThanThresholdValues('Devices in error or offline', devices, 0, 100))
    setErrors(errorsFound)
    return errorsFound.length === 0
  }

  useMountEffect(() => {
    getDefaultThresholds()
    getSettings(teamID)
  })

  const handleResetToDefaults = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    resetToDefaults(defaultThresholds)
  }

  const resetToDefaults = (defaults: ThresholdSettings) => {
    setCpu(defaults.cpu)
    setDatabaseConnections(defaults.databaseConnections)
    setDatabaseResponseTime(defaults.databaseResponseTimeMillis)
    setDiskspace(defaults.diskSpaceFreeGb)
    setMemory(defaults.memoryUsedPercentage)
    setDevices(defaults.devicesDownPercentage)
    setMobility(defaults.mobilityPrintOfflinePercentage)
    setPrintProvider(defaults.printProviderOfflinePercentage)
    setSiteServer(defaults.siteServerOfflinePercentage)
  }

  const saveChanges = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    saveSettings()
  }

  return (
    <div id='tab-healththresholds'>
      <div className='row flex'>
        <div className='col forty'>
          <h4>Monitoring thresholds</h4>
          <h5>System health</h5>
          <p>Customize the thresholds for system health status reporting and notifications.</p>
          <p>
            You will receive critical or warning notifications based on your defined threshold values for each parameter. These thresholds will be used to determine the system
            health status of your customers that is displayed in the PaperCut Multiverse dashboard.
          </p>
          <p>
            Default threshold values can be found in the tooltip{' '}
            <i className='icon-info'>
              <InfoIcon />
            </i>{' '}
            next to each parameter.
          </p>
          {readOnly && <p>To change the values, please contact your admin.</p>}
        </div>

        {!loaded && (
          <div className='col sixty'>
            <div className='empty loading'>
              <LoadingDots />
            </div>
          </div>
        )}
        {loaded && (
          <div className='col sixty'>
            <div className='list'>
              <div className='surface'>
                {errors.length > 0 &&
                  errors.map(error => (
                    <div key={error} className='alert error'>
                      <i className='icon-exclaim'>
                        <ExclaimIcon />
                      </i>
                      <div className='message'>{error}</div>
                    </div>
                  ))}

                <div className='table'>
                  <div className='row flex'>
                    <div className='col forty freq' />

                    <div className='col fifth center'>
                      <i className='health error icon'>
                        <SmallCriticalHeartbeat />
                      </i>
                      <h5>Critical</h5>
                    </div>

                    <div className='col fifth center'>
                      <i className='health warning icon'>
                        <SmallWarningHeartbeat />
                      </i>
                      <h5>Warning</h5>
                    </div>

                    <div className='col fifth center'>
                      <i className='health icon'>
                        <SmallGoodHeartbeat />
                      </i>
                      <h5>Normal</h5>
                    </div>
                  </div>

                  <header>
                    <div className='row'>
                      <div className='col fp'>
                        <h5>Infrastructure</h5>
                      </div>
                    </div>
                  </header>

                  <MetricSettingRow
                    name='Database connections used'
                    units=''
                    comparator='>'
                    threshold={databaseConnections}
                    setThreshold={setDatabaseConnections}
                    defaultValues={defaultThresholds.databaseConnections}
                    description='Number of database connections used at any point.'
                    readOnly={readOnly}
                  />
                  <MetricSettingRow
                    name='Database response time'
                    units=' ms'
                    comparator='>'
                    threshold={databaseResponseTime}
                    setThreshold={setDatabaseResponseTime}
                    defaultValues={defaultThresholds.databaseResponseTimeMillis}
                    description='Time database takes to respond to a query.'
                    readOnly={readOnly}
                  />
                  <MetricSettingRow
                    name='Disk space free'
                    units=' GB'
                    comparator='<'
                    threshold={diskspace}
                    setThreshold={setDiskspace}
                    defaultValues={defaultThresholds.diskSpaceFreeGb}
                    description='Remaining disk space out of total allocated.'
                    readOnly={readOnly}
                  />
                  <MetricSettingRow
                    name='CPU utilization'
                    units='%'
                    comparator='>'
                    threshold={cpu}
                    setThreshold={setCpu}
                    defaultValues={defaultThresholds.cpu}
                    description='Total CPU utilization.'
                    readOnly={readOnly}
                  />
                  <MetricSettingRow
                    name='Memory used'
                    units='%'
                    comparator='>'
                    threshold={memory}
                    setThreshold={setMemory}
                    defaultValues={defaultThresholds.memoryUsedPercentage}
                    description='Utilization of application memory allocated to PaperCut instance.'
                    readOnly={readOnly}
                  />
                </div>

                <div className='table'>
                  <header>
                    <div className='row'>
                      <div className='col fp'>
                        <h5>Print management</h5>
                      </div>
                    </div>
                  </header>

                  <MetricSettingRow
                    name='Print providers offline'
                    units='%'
                    comparator='>'
                    threshold={printProvider}
                    setThreshold={setPrintProvider}
                    defaultValues={defaultThresholds.printProviderOfflinePercentage}
                    readOnly={readOnly}
                  />
                  <MetricSettingRow
                    name='Site servers offline'
                    units='%'
                    comparator='>'
                    threshold={siteServer}
                    setThreshold={setSiteServer}
                    defaultValues={defaultThresholds.siteServerOfflinePercentage}
                    readOnly={readOnly}
                  />
                  <MetricSettingRow
                    name='BYOD/Mobility Print servers offline'
                    units='%'
                    comparator='>'
                    threshold={mobility}
                    setThreshold={setMobility}
                    defaultValues={defaultThresholds.mobilityPrintOfflinePercentage}
                    readOnly={readOnly}
                  />
                  <MetricSettingRow
                    name='Devices in error or offline'
                    units='%'
                    comparator='>'
                    threshold={devices}
                    setThreshold={setDevices}
                    defaultValues={defaultThresholds.devicesDownPercentage}
                    readOnly={readOnly}
                  />
                </div>

                {!readOnly && (
                  <footer className={styles.footer}>
                    <a href='/#' onClick={handleResetToDefaults} className={styles.left}>
                      Reset to defaults
                    </a>
                    <a href='/#' className='btn' onClick={saveChanges}>
                      Save changes
                    </a>
                  </footer>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

interface MetricSettingProps {
  name: string
  description?: string
  units: string
  comparator: string
  threshold: ThresholdValues
  setThreshold: SetThresholdFunc
  defaultValues?: ThresholdValues
  readOnly: boolean
}

const MetricSettingRow: FunctionComponent<MetricSettingProps> = ({ name, units, comparator, threshold, setThreshold, defaultValues, description, readOnly }) => {
  return (
    <div className='row flex flex-ver'>
      <div className='col forty'>
        <label className='inl'>
          {name}
          {defaultValues && (
            <i className='icon-info'>
              <span className='tip wide'>
                <strong className='blk heading'>{name}</strong>
                {description}
                <hr className='divider' />
                <span className='blk'>
                  <strong className='blk'>Default threshold values</strong>
                  Critical: {comparator} {defaultValues.critical}
                  {units} Warning: {comparator} {defaultValues.warning}
                  {units}
                </span>
              </span>
              <InfoIcon />
            </i>
          )}
        </label>
      </div>

      <div className={`col fifth center`}>
        <div className='max-100'>
          <span className={styles.inputPrefix}>{comparator}</span>
          <input
            type='text'
            value={threshold.critical}
            className={`inl ${styles.inl}`}
            disabled={readOnly}
            aria-label={`Critical threshold for ${name}`}
            onChange={e => {
              if (e.target.value && !+e.target.value) return
              let newThreshold = { ...threshold }
              newThreshold.critical = +e.target.value || 0
              setThreshold(newThreshold)
            }}
          />
          {units.trim()}
        </div>
      </div>

      <div className={`col fifth center`}>
        <div className='max-100'>
          <span className={styles.inputPrefix}>{comparator}</span>
          <input
            type='text'
            value={threshold.warning}
            className={`inl ${styles.inl}`}
            disabled={readOnly}
            aria-label={`Warning threshold for ${name}`}
            onChange={e => {
              if (e.target.value && !+e.target.value) return
              let newThreshold = { ...threshold }
              newThreshold.warning = +e.target.value
              setThreshold(newThreshold)
            }}
          />
          {units.trim()}
        </div>
      </div>

      <div className={`col fifth center`}>
        <div className='max-100'>
          <span>
            {threshold.warning}
            {units}
            {threshold.warning > 0 && (comparator === '>' ? ' or less' : ' or more')}
          </span>
        </div>
      </div>
    </div>
  )
}

function validateGreaterThanThresholdValues(name: string, settings: ThresholdValues, min: number, max: number): string[] {
  let errors = []
  if (settings.warning > settings.critical) {
    errors.push(name + ' warning threshold must be lower than the critical threshold')
  }
  if (settings.warning < min) {
    errors.push(name + ' threshold must be a minimum of ' + min)
  }
  if (settings.critical > max) {
    errors.push(name + ' threshold must be a maximum of ' + max)
  }
  return errors
}

function validateLessThanThresholdValues(name: string, settings: ThresholdValues, min: number, max: number): string[] {
  let errors = []
  if (settings.warning < settings.critical) {
    errors.push(name + ' warning threshold must be greater than the critical threshold')
  }
  if (settings.critical < min) {
    errors.push(name + ' threshold must be a minimum of ' + min)
  }
  if (settings.warning > max) {
    errors.push(name + ' threshold must be a maximum of ' + max)
  }
  return errors
}
