import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import { defaultAuthHandler } from '../../auth'
import { PasswordStrengthValidator } from '../../service/PasswordStrengthValidator'
import { InfoIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'
import Toaster from '../Toaster'

interface Props {
  reqPasswordChange: (password: string) => Promise<boolean>
  close: () => void
}

const PasswordModal: FunctionComponent<Props> = props => {
  const [password, setPassword] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value)
  }

  const handleConfirmChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPwd(event.currentTarget.value)
  }

  const onClickCancel = () => {
    setPassword('')
    setConfirmPwd('')
    props.close()
  }

  const onClickSaveChanges = () => {
    if (password === '') {
      Toaster.notifyFailure('Please enter a password.')
      return
    }

    if (PasswordStrengthValidator.validate(password)) {
      if (password === confirmPwd) {
        props.reqPasswordChange(password).then(res => {
          if (res) {
            defaultAuthHandler.logout()
          } else {
            Toaster.notifyFailure('Unable to change password.')
          }
        })
      } else {
        Toaster.notifyFailure('Passwords do not match. Please re-type and confirm new password.')
      }
    } else {
      Toaster.notifyFailure('Password is too weak - it must include a number, a lower-case letter, an upper-case letter and a special character.')
    }

    setPassword('')
    setConfirmPwd('')
  }

  return (
    <CustomModal id='changePasswordModal' close={onClickCancel}>
      <header className='center'>
        <h3>Change password</h3>

        <p>After changing the password you will need to log in again with your new password.</p>
      </header>

      <form
        onSubmit={(event: any) => {
          event.preventDefault()
          return false
        }}
      >
        <div className='content'>
          <div className='field'>
            <label id='password-label' className='flex'>
              New password
              <i className='icon-info'>
                <div className='tip' id='tip' style={{ width: '230px' }}>
                  <h5>Password requirements:</h5>
                  <ul className='passwordPolicy'>
                    <li id='characters'>at least eight characters</li>
                    <li id='lowerCase'>at least one lower case letter (a-z)</li>
                    <li id='upperCase'>at least one upper case letter (A-Z)</li>
                    <li id='numbers'>at least one number (0-9)</li>
                    <li id='sp-characters'>at least one special character (#,@,$,&amp;)</li>
                  </ul>
                </div>

                <InfoIcon />
              </i>
            </label>

            <input type='password' autoFocus={true} value={password} onChange={handleChange} aria-labelledby='password-label' />
          </div>

          <div className='field'>
            <label id='confirm-password-label'>Confirm new password</label>

            <input type='password' value={confirmPwd} onChange={handleConfirmChange} aria-labelledby='confirm-password-label' />
          </div>
        </div>
      </form>

      <footer>
        <button className='btn outline' onClick={onClickCancel}>
          Cancel
        </button>

        <button className='btn' onClick={onClickSaveChanges}>
          <span className='text'>Save changes &amp; sign out </span>
        </button>
      </footer>
    </CustomModal>
  )
}

export default PasswordModal
