import React, { FunctionComponent, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import Moment from 'react-moment'
import { useMountEffect } from '../../hooks/MountEffect'
import Hamburger from '../Hamburger'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'
import styles from './Note.module.css'

interface NoteRequest {
  text: string
}

interface NoteResponse {
  text: string
  user: string
  occurredAt: string // golang time
  id: string
}

interface Props {
  teamID: string
  hostID: string
  top?: boolean
}

export const Note: FunctionComponent<Props> = ({ teamID, hostID, top = false }) => {
  const maxNoteLen = 1500 // based upon maximum datastore.ShortBlob bytes
  const [noteText, setNoteText] = useState('')
  const [notes, setNotes] = useState<NoteResponse[]>([])
  const [waiting, setWaiting] = useState(true)
  const [loaded, setLoaded] = useState(false)

  const deleteNote = (noteID: string) => {
    axios
      .delete(`/api/teams/${teamID}/customers/${hostID}/notes/${noteID}`)
      .then(_ => {
        const newNotes = [...notes].filter(note => note.id !== noteID)
        setNotes(newNotes)
      })
      .catch(_ => {
        Toaster.notifyFailure('Unable to delete note.')
      })
      .finally(() => setWaiting(false))
  }

  const editNote = (noteID: string, newText: string) => {
    return axios
      .put<NoteRequest, AxiosResponse<NoteResponse>>(`/api/teams/${teamID}/customers/${hostID}/notes/${noteID}`, {
        text: newText,
      })
      .then(resp => {
        const newNotes = [resp.data, ...notes.filter(n => n.id !== noteID)]
        setNotes(newNotes)
        Toaster.notifySuccess('Note successfully updated.')
        return Promise.resolve()
      })
      .catch(_ => {
        Toaster.notifyFailure('Unable to edit note.')
        return Promise.reject()
      })
      .finally(() => setWaiting(false))
  }

  const postNote = () => {
    setWaiting(true)
    axios
      .post<{ text: string }, AxiosResponse<NoteResponse>>(`/api/teams/${teamID}/customers/${hostID}/notes`, {
        text: noteText,
      })
      .then(result => {
        setNoteText('')
        const addedNotes = [result.data].concat(...notes)
        setNotes(addedNotes)
      })
      .catch(_ => {
        Toaster.notifyFailure('Unable to post note.')
      })
      .finally(() => setWaiting(false))
  }

  useMountEffect(() => {
    const controller = new AbortController()
    axios
      .get<NoteResponse[]>(`/api/teams/${teamID}/customers/${hostID}/notes`, { signal: controller.signal })
      .then(result => {
        setNotes(result.data)
        setLoaded(true)
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          Toaster.notifyFailure('Unable to load notes.')
        }
      })
      .finally(() => setWaiting(false))
    return () => {
      controller.abort()
    }
  })

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && noteText.trim().length > 0) {
      postNote()
    }
  }

  return (
    <>
      <div className={`${top ? '' : 'submit'} flex flex-nowrap`} style={{ paddingBottom: top ? '20px' : '' }}>
        <input
          type='text'
          value={noteText}
          placeholder='Enter your new note text'
          disabled={waiting}
          onChange={e => setNoteText(e.target.value)}
          onKeyDown={handleInputKeyDown}
          maxLength={maxNoteLen}
        />

        <button className={`btn ${waiting || noteText.trim().length < 1 ? 'disabled' : ''}`} onClick={postNote}>
          Post note
        </button>
      </div>

      {!loaded && waiting && (
        <div className='loading empty'>
          <LoadingDots />
        </div>
      )}

      <div className='list'>
        <ul>
          {notes.length === 0 && loaded && (
            <li>
              <div className='row'>
                <div className='col' />

                <div className='col'>No notes have been posted.</div>
              </div>
            </li>
          )}

          {notes.map(note => (
            <NoteRow key={note.id} note={note} onDelete={deleteNote} onEdit={editNote} />
          ))}
        </ul>
      </div>
    </>
  )
}

const NoteRow: FunctionComponent<{
  note: NoteResponse
  onDelete: (noteID: string) => void
  onEdit: (noteID: string, noteText: string) => Promise<void>
}> = ({ note, onDelete, onEdit }) => {
  const [isEditing, setEditing] = useState(false)
  const [noteText, setNoteText] = useState(note.text)
  const [isLoading, setLoading] = useState(false)

  const editNote = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setEditing(true)
  }

  const saveEdit = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setLoading(true)
    onEdit(note.id, noteText)
      .then(() => setEditing(false))
      .finally(() => setLoading(false))
  }

  const cancelEdit = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setNoteText(note.text)
    setEditing(false)
  }

  const deleteNote = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    onDelete(note.id)
  }

  return (
    <li key={note.id}>
      <div className='row'>
        <div className={`col flex flex-nowrap ${styles.message}`} data-heap-redact-text>
          {isEditing && (
            <>
              <input type='text' disabled={isLoading} value={noteText} onChange={e => setNoteText(e.target.value)} placeholder='Enter the new note text' />

              <a href='#' className={`cancel ${isLoading ? 'disabled' : ''}`} onClick={cancelEdit} />

              <a href='#' className={`save ${isLoading || noteText.trim().length < 1 ? 'disabled' : ''}`} onClick={saveEdit} />
            </>
          )}
          {!isEditing && note.text}
        </div>

        <div className={`col ellipsis ${styles.user}`} data-heap-redact-text>
          {note.user}
        </div>

        <div className={`col time ${styles.time}`}>
          <Moment format='D MMM YYYY h:mm A' date={note.occurredAt} />
        </div>

        <div className='col'>
          <Hamburger>
            <ul>
              <li className='trash'>
                <a href='#' className={isLoading ? 'disabled' : ''} onClick={deleteNote}>
                  Remove note
                </a>
              </li>

              <li className='revise'>
                <a href='#' className={isLoading ? 'disabled' : ''} onClick={e => editNote(e)}>
                  Edit note
                </a>
              </li>
            </ul>
          </Hamburger>
        </div>
      </div>
    </li>
  )
}
