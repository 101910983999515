import React, { FunctionComponent, useState } from 'react'
import axios from 'axios'
import { useMountEffect } from '../../hooks/MountEffect'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import CustomModal from '../MyModal/CustomModal'
import { User } from '../Team/Data'
import Toaster from '../Toaster'

interface Props {
  teamID: string
  onClose: () => void
}

const maxUsersToDisplay = 5

export const DeleteTeamModal: FunctionComponent<Props> = props => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [removeAccessUsers, setRemoveAccessUsers] = useState<User[]>([])
  const [isLoading, setLoading] = useState(true)

  useMountEffect(() => {
    axios
      .get<{ users: User[] }>(`/api/teams/${props.teamID}/users?single-team`)
      .then(resp => {
        setRemoveAccessUsers(resp.data.users)
      })
      .finally(() => setLoading(false))
  })

  const deleteTeam = async () => {
    setIsDeleting(true)
    await axios
      .delete(`/api/teams/${props.teamID}`)
      .then(_ => {
        window.location.href = '/my-customers/list'
      })
      .catch(() => {
        setIsDeleting(false)
        Toaster.notifyFailure('Failed to delete team. Please try again. If this persists please contact support.')
      })
  }

  return (
    <>
      {!isLoading && !isDeleting && (
        <ConfirmationModal
          title='Are you sure you want to delete this team?'
          message={
            <>
              <p>
                Removing this team will remove all associated team members and data.
                {removeAccessUsers.length > 0 && ' These team members will no longer be able to access PaperCut Multiverse:'}
              </p>

              {removeAccessUsers.length > 0 && (
                <ul>
                  {removeAccessUsers.slice(0, maxUsersToDisplay).map(u => (
                    <li key={u.email}>
                      {u.email} <small>[{u.roles.join(', ')}]</small>
                    </li>
                  ))}

                  {removeAccessUsers.length > maxUsersToDisplay && <li>+{removeAccessUsers.length - maxUsersToDisplay} more users...</li>}
                </ul>
              )}
            </>
          }
          onClose={props.onClose}
          onYes={deleteTeam}
          yesLabel='Delete team'
        />
      )}

      {(isLoading || isDeleting) && (
        <CustomModal
          id='delete-team'
          close={() => {
            if (isLoading) props.onClose()
          }}
        >
          <div className='flex'>
            <div className='empty'>
              {isDeleting && <div className='center'>Deleting team</div>}

              <div className='loading margin-top'>
                <LoadingDots />
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  )
}
