import { HeartRateIcon } from '../Icons/Icons'
import React, { FunctionComponent } from 'react'

interface Props {
  name: string
}

export const RemoteMonitoringDisabledTab: FunctionComponent<Props> = ({ name }) => {
  return (
    <div className='tab'>
      <div className='content'>
        <div className='empty'>
          <i className='icon thirtytwo'>
            <HeartRateIcon />
          </i>
          <h5>{name} has disabled remote monitoring.</h5>
          <p>In order to view system health and feature usage, please enable remote monitoring in the PaperCut Multiverse Connector.</p>
        </div>
      </div>
    </div>
  )
}
