import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { defaultAuthHandler } from './auth'
import { LegacyOrg } from './components/Pmitc/LegacyOrg'
import { Org } from './components/Pmitc/Org'
import { AuthenticationCallbackRoute } from './components/routing/AuthenticationCallbackRoute'
import LandingPage from './components/routing/LandingPage'
import AppContainer from './routes/AppContainer'
import { DisconnectedOrg } from './components/Pmitc/DisconnectedOrg'
import PrivateRouteContainer from './components/routing/PrivateRouteContainer'

export const MainRoutes = () => {
  return (
    <Routes>
      <Route path='/landing' element={<LandingPage />} />
      <Route path='/callback' element={<AuthenticationCallbackRoute />} />
      <Route path='/pmitc/orgs/:orgId/*' element={<LegacyOrg />} />
      <Route path='/pmitc/data-centers/:dataCenter/orgs/:orgId/*' element={<Org />} />
      <Route path='/pmitc/disconnected' element={<DisconnectedOrg />} />

      <Route
        path='/*'
        element={
          <PrivateRouteContainer authHandler={defaultAuthHandler}>
            <AppContainer />
          </PrivateRouteContainer>
        }
      />
    </Routes>
  )
}
