import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useMountEffect } from '../../hooks/MountEffect'
import { Reseller } from '../ContactSupport/Data'
import { ExclaimIcon, InfoIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import CustomModal from '../MyModal/CustomModal'
import { TeamSettingsData } from '../TeamSettings/TeamSettings'
import Toaster from '../Toaster'

interface Props {
  onClose: () => void
  currentTeam: TeamSettingsData
  parents: Reseller[]
}

export const CreateTeamModal: FunctionComponent<Props> = props => {
  const { onClose } = props
  const [teamName, setTeamName] = useState('')
  const [publicName, setPublicName] = useState(props.currentTeam.publicName === props.currentTeam.name ? '' : props.currentTeam.publicName)
  const [errors, setErrors] = useState<string[]>([])
  const [saving, setSaving] = useState(false)
  const [demoTeamSelected, setDemoTeamSelected] = useState(false)
  const [useAltPublicName, setUseAltPublicName] = useState(true)
  const ref = useRef<HTMLInputElement>(null)
  useMountEffect(() => {
    ref.current?.focus()
  })

  const handleClose = useCallback(() => {
    if (!saving) onClose()
  }, [saving, onClose])

  function validate() {
    let errs = []
    if (teamName.trim().length === 0) {
      errs.push('Please enter a valid team name.')
    }
    if (useAltPublicName && publicName.trim() === '') {
      errs.push('Please enter a valid customer-facing team name.')
    }

    return errs
  }

  const handleCreateTeam = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const newErrors = validate()
    setErrors(newErrors)
    if (newErrors.length > 0) {
      return
    }

    setSaving(true)

    interface Request {
      name: string
      publicName: string
      demo: boolean
    }

    const req: Request = {
      name: teamName,
      publicName: useAltPublicName ? publicName : teamName,
      demo: demoTeamSelected,
    }
    axios
      .post<Request, AxiosResponse<{ url: string }>>('/api/teams', req)
      .then(result => {
        window.location.href = result.data.url
      })
      .catch(_ => {
        setSaving(false)
        Toaster.notifyFailure('Unable to create new team.')
      })
  }

  const copyCurrentAlternate = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setPublicName(props.currentTeam.publicName)
  }

  const handleTypeSwitch = (e: React.MouseEvent<HTMLAnchorElement>, demo: boolean) => {
    e.preventDefault()
    setDemoTeamSelected(demo)
    setErrors([])
    setUseAltPublicName(!demo)
  }

  return (
    <CustomModal id='create-team' close={handleClose}>
      <div className='flex'>
        {saving && (
          <div className='empty'>
            <div className='center'>Creating team</div>

            <div className='loading margin-top'>
              <LoadingDots />
            </div>
          </div>
        )}
        {!saving && (
          <div className='main'>
            <header className='center'>
              <h3>Add a new team</h3>
            </header>

            <div className='content'>
              <div className='field'>
                <label>What kind of team would you like?</label>

                <ul className='flex center option-list'>
                  <li className={`${!demoTeamSelected ? 'active' : ''}`}>
                    <a href='#' onClick={e => handleTypeSwitch(e, false)}>
                      <strong>Live</strong>

                      <small className='blk'>For real customers, production-ready features</small>
                    </a>
                  </li>

                  <li className={`${demoTeamSelected ? 'active' : ''}`}>
                    <a href='#' onClick={e => handleTypeSwitch(e, true)}>
                      <strong>Demo</strong>

                      <small className='blk'>With sample data, upcoming features, limited functionality</small>
                    </a>
                  </li>
                </ul>
              </div>

              <hr className='divider' />

              {errors.length > 0 && (
                <div className='field'>
                  {errors.map(error => (
                    <div key={error} className='alert error'>
                      <i className='icon-exclaim'>
                        <ExclaimIcon />
                      </i>

                      <div className='message'>{error}</div>
                    </div>
                  ))}
                </div>
              )}

              <div className='field'>
                <label>{demoTeamSelected ? 'Demo team name' : 'Team name'}</label>

                <div className='flex flex-nowrap'>
                  <input ref={ref} type='text' value={teamName} onChange={e => setTeamName(e.target.value)} placeholder='Enter team name' name='teamNameInput' />
                </div>
              </div>

              {!demoTeamSelected && (
                <>
                  <div className='field'>
                    <label className='inl'>
                      <input type='checkbox' checked={useAltPublicName} onChange={() => setUseAltPublicName(!useAltPublicName)} />
                      Use alternative customer facing team name
                    </label>

                    {useAltPublicName && (
                      <>
                        <label>
                          Customer-facing team name{' '}
                          <i className='icon-info'>
                            <InfoIcon />
                            <span className='tip wide'>
                              <span className='blk'>
                                This will be used to identify you to customers, for example in the Connector settings page so they know who has access to their environment.
                              </span>
                            </span>
                          </i>
                        </label>

                        <div className='flex flex-nowrap'>
                          <input type='text' value={publicName} onChange={e => setPublicName(e.target.value)} placeholder='Enter customer facing team name' name='orgNameInput' />

                          <div>
                            <a className='btn secondary' href='#' onClick={copyCurrentAlternate}>
                              Copy current
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {props.currentTeam.resellerCode && (
                    <div className='field'>
                      <label>Reseller code</label>

                      <div className='flex flex-nowrap' data-heap-redact-text>
                        {props.currentTeam.resellerCode}
                      </div>
                    </div>
                  )}

                  {props.parents.length > 0 && (
                    <div className='field'>
                      <label>Authorized Partner</label>

                      {props.parents.map(p => (
                        <div key={p.id} className='flex flex-nowrap' data-heap-redact-text>
                          {p.name}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>

            <footer>
              <a id='add' href='#' className='btn' onClick={handleCreateTeam}>
                Add new team
              </a>
            </footer>
          </div>
        )}
      </div>
    </CustomModal>
  )
}
